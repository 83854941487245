import "./navbar.scss";

const Navbar = () => {
  return (
    <div className="navbar">
      <div className="wrapper-search">
      </div>
    </div>
  );
};

export default Navbar;
