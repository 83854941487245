import "../../style/new.scss";

import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useState, useMemo } from "react";
import { useEffect } from "react";
import axios from "axios";
import PopupAlert from "../../components/popupalert/popupAlert";
import Select from "react-select";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { IoIosAddCircle } from "react-icons/io";

import { uid } from "uid";
import { useNavigate } from "react-router-dom";

const RandomDigit = uid();

const randomString = RandomDigit.replace(/[^0-9a-zA-Z]/g, "") // remove non-digits
  .substring(0, 7); // extract first 5 digits

const NewMedicine = ({ title }) => {
  const [name, setName] = useState("");
  const [bannerImage, setBannerImage] = useState(null);
  const [description, setDescription] = useState();
  const [benefits, setBenefits] = useState();
  const [sideeffects, setSideEffects] = useState();
  const [directions, setDirections] = useState();
  const [images, setImages] = useState([]);
  // const [instructions, setInstructions] = useState();
  const [ingredients, setingredients] = useState([]);
  const [category, setcategory] = useState("");
  const [genre, setGenre] = useState("");
  const [symptoms, setsymptoms] = useState([]);
  const [popUpShow, setPopupshow] = useState(false);
  const [popUpText, setPopupText] = useState("");
  const [allCategories, setAllCategories] = useState([]);
  const [allGenres, setAllGenres] = useState([]);
  const [allSymptoms, setAllSymptoms] = useState([]);
  const [ingredientName, setIngredientName] = useState("");
  const [weightage, setWeightage] = useState();
  const [measurement, setMeasurement] = useState("");
  const [price, setPrice] = useState();
  const [openCategoryModal, setCategoryModal] = useState(false);
  const [openSymptomModal, setSymptomModal] = useState(false);
  const [openGenreModal, setGenreModal] = useState(false);
  const [SymptomName, setSymptomName] = useState("");
  const [genreName, setGenreName] = useState("");
  const [genreImage, setGenreImage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [catgoryImage, setCatgoryImage] = useState(null);
  const [categoryDescription, setcategoryDescription] = useState("");
  const [openMeasurementModal, setMeasurementModal] = useState(false);
  const [allMeasurements, setAllMeasurement] = useState([]);
  const [measurementName, setMeasurementName] = useState("");
  const navigate = useNavigate();

  // for symptom selection
  const updatedSymptoms = allSymptoms.map((item) => ({
    ...item,
    value: item.name,
    label: item.name,
  }));

  // get categories, symptoms and genres
  useEffect(() => {
    axios
      .get("https://paul-brooks-api-7a80d7089006.herokuapp.com/categories")
      .then((response) => {
        if (response.data.length > 0) {
          setAllCategories(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get("https://paul-brooks-api-7a80d7089006.herokuapp.com/genres")
      .then((response) => {
        if (response.data.length > 0) {
          setAllGenres(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get("https://paul-brooks-api-7a80d7089006.herokuapp.com/symptoms")
      .then((response) => {
        if (response.data.length > 0) {
          setAllSymptoms(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get("https://paul-brooks-api-7a80d7089006.herokuapp.com/measurements")
      .then((response) => {
        setAllMeasurement(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [allSymptoms, allCategories, allGenres, allMeasurements]);

  // create medicine
  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("name", name);
    formData.append("description", JSON.stringify(description));
    formData.append("benefits", JSON.stringify(benefits));
    symptoms.map((symptom, index) => {
      formData.append(`symptoms[${index}][name]`, symptom._id);
    });
    ingredients.forEach((ingredient, index) => {
      formData.append(
        `ingredients[${index}][ingredientName]`,
        ingredient.ingredientName
      );
      formData.append(`ingredients[${index}][weightage]`, ingredient.weightage);
      formData.append(
        `ingredients[${index}][measurement]`,
        ingredient.measurement
      );
    });
    formData.append("sideeffects", JSON.stringify(sideeffects));
    formData.append("price", price);
    formData.append("category", category);
    formData.append("genre", genre);
    formData.append("medicineId", randomString);
    formData.append("directions", JSON.stringify(directions));
    // formData.append("instructions", JSON.stringify(instructions));

    formData.append("bannerImage", bannerImage);
    images.forEach((image) => {
      formData.append("images", image);
    });
    if (
      images.length > 0 &&
      category !== "" &&
      genre !== "" &&
      description !== ""
    ) {
      axios
        .post(
          "https://paul-brooks-api-7a80d7089006.herokuapp.com/medicines/new",
          formData
        )
        .then((response) => {
          console.log(response.data);
          setName("");
          setPopupshow(true);
          setPopupText("Medicine Added");
          setBannerImage(null);
          setsymptoms([]);
          setSideEffects([]);
          setBenefits([]);
          setDirections([]);
          setDescription([]);

          setImages([]);
          setingredients([]);
          setcategory("");
          setTimeout(() => {
            setPopupshow(false);
            navigate("/medicines");
          }, 2000);

          setGenre("");
        })
        .catch((error) => {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            if (error.response) {
              console.log(error.response);
              // window.alert(error.response.data.message);
              // window.location.reload();
            }
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
        });
    } else {
      window.alert("Please fill all the required fields of medicines");
    }
  };

  // create symptom
  const handleAddSymptom = (e) => {
    e.preventDefault();

    const Symptom = {
      name: SymptomName,
    };

    axios
      .post("https://paul-brooks-api-7a80d7089006.herokuapp.com/symptoms", Symptom)
      .then((res) => {
        setPopupText(`Symptom Added`);
        setSymptomName("");
        setPopupshow(true);
        setSymptomModal(false);
        setErrorMessage(false);
        setTimeout(() => {
          setPopupshow(false);
        }, 2000);
      })
      .catch((err) => {
        console.log(err.response.data);
        setErrorMessage(true);
      });
    setTimeout(() => {
      setPopupshow(false);
    }, 2000);
  };

  // create genre
  const handleGenreSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("name", genreName);

    formData.append("image", genreImage);
    if (genreImage) {
      axios
        .post(
          "https://paul-brooks-api-7a80d7089006.herokuapp.com/genres/new",
          formData
        )
        .then((response) => {
          if (response.data.error === "Genre already exists") {
            setErrorMessage(true);
            setGenreName("");
            setGenreImage(null);
            setGenreModal(false);
          } else {
            setGenreName("");
            setPopupshow(true);
            setPopupText("Genre Added");
            setErrorMessage(false);
          }
        })
        .catch((error) => {
          console.error(error);
          setErrorMessage(true); // will log "Genre already exists"
        });
    } else {
      window.alert("Please fill all the required fields of genre");
    }
    setTimeout(() => {
      setPopupshow(false);
    }, 2000);
  };

  // create category
  const handleCategorySubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("name", categoryName);
    formData.append("description", categoryDescription);

    formData.append("image", catgoryImage);
    if (catgoryImage) {
      axios
        .post(
          "https://paul-brooks-api-7a80d7089006.herokuapp.com/categories/new",
          formData
        )
        .then((response) => {
          console.log(response.data);
          if (response.data.error === "Category already exists") {
            setErrorMessage(true);
            setCategoryName("");
            setCatgoryImage(null);
          } else {
            setcategoryDescription("");
            setCategoryName("");
            setPopupshow(true);
            setCategoryModal(false);
            setPopupText("Category Added");
            setErrorMessage(false);
            setCatgoryImage(null);
            setTimeout(() => {
              setPopupshow(false);
            }, 2000);
          }
        })
        .catch((error) => {
          console.error(error);
          setErrorMessage(true); // will log "Category already exists"
        });
    } else {
      window.alert("Please fill all the required fields of category");
    }
    setTimeout(() => {
      setPopupshow(false);
    }, 2000);
  };

  const handleAddMeasurement = (e) => {
    e.preventDefault();
    const data = { name: measurementName };
    axios
      .post("https://paul-brooks-api-7a80d7089006.herokuapp.com/measurements", data)
      .then((response) => {
        console.log(response.data);
        if (response.data.error === "Measurement Unit already exists") {
          setErrorMessage(true);
          setMeasurementName("");
        } else {
          setMeasurementName("");
          setPopupshow(true);
          setMeasurementModal(false);
          setPopupText("Measurement Added");
          setErrorMessage(false);
          setTimeout(() => {
            setPopupshow(false);
          }, 2000);
        }
      })
      .catch((error) => {
        console.error(error);
        setErrorMessage(true); // will log "Category already exists"
      });
  };

  // Medicine image
  const handleImageUpload = (event) => {
    const selectedImages = Array.from(event.target.files);

    setImages([...images, ...selectedImages]);
  };

  // genre image
  const handleGenreImageUpload = (event) => {
    setGenreImage(event.target.files[0]);
  };

  // vategory image
  const handleCategorymageUpload = (event) => {
    setCatgoryImage(event.target.files[0]);
  };

  //Add ingredients
  const addIngredients = () => {
    if (!ingredientName || !measurement || !weightage) {
      alert("Please fill all the ingredients input");
    } else {
      setingredients([
        ...ingredients,
        {
          ingredientName: ingredientName,
          weightage: weightage,
          measurement: measurement,
        },
      ]);
      setIngredientName("");
      setWeightage("");
    }
  };

  const quillModules = {
    toolbar: [
      ["bold", "italic", "underline", "strike"], // text formatting options
      [{ header: [1, 2, 3, 4, 5, 6, false] }], // header styles
      [{ list: "ordered" }, { list: "bullet" }], // lists
      ["link", "image"], // link and image options
      [{ color: [] }], // color option
      [{ align: [] }], // text alignment option
      [{ size: ["small", false, "large", "huge"] }],
      [{ font: [] }], // custom font style option
      ["clean"], // remove formatting
    ],
  };

  const quillFormats = [
    "bold",
    "italic",
    "underline",
    "strike",
    "header",
    "list",
    "bullet",
    "link",
    "image",
    "color",
    "align",
    "font",
    "size",
  ];

  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        {popUpShow ? (
          <PopupAlert popUpText={popUpText} backgroundColor={"#8AFF8A"} />
        ) : (
          ""
        )}

        {/*Add symptom modal*/}
        {openSymptomModal ? (
          <div className="modal">
            <div className="modalInner">
              <p
                className="closeModal"
                onClick={() => {
                  setSymptomModal(false);
                  setErrorMessage(false);
                }}>
                X
              </p>
              <div style={{ margin: 40 }}>
                {errorMessage ? (
                  <div style={{ color: "red", fontSize: 10 }}>
                    Symptom already exist
                  </div>
                ) : (
                  ""
                )}
                <form className="category-new" onSubmit={handleAddSymptom}>
                  <input
                    type="text"
                    placeholder="Symptom Name"
                    className="category-form"
                    value={SymptomName}
                    onChange={(e) => {
                      setSymptomName(e.target.value);
                    }}
                  />

                  <button className="createButton">Add</button>
                </form>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        {/*Add genre modal*/}
        {openGenreModal ? (
          <div className="modal">
            <div className="modalInner">
              <p
                className="closeModal"
                onClick={() => {
                  setGenreModal(false);
                  setErrorMessage(false);
                }}>
                X
              </p>
              <div style={{ margin: 40 }}>
                {errorMessage ? (
                  <div style={{ color: "red", fontSize: 10 }}>
                    Genre already exist
                  </div>
                ) : (
                  ""
                )}

                <form
                  className="form-new"
                  onSubmit={handleGenreSubmit}
                  method="post"
                  encType="multipart/form-data"
                  style={{ display: "flex", flexDirection: "column" }}
                  action="/upload">
                  <div
                    className="formInput"
                    style={{ display: "flex", flexDirection: "column" }}>
                    <label className="label-form">Genre Name*</label>
                    <input
                      type="text"
                      placeholder="Health Care"
                      className="input-form"
                      value={genreName}
                      onChange={(e) => {
                        setGenreName(e.target.value);
                      }}
                      required
                    />
                    <label className="label-form">
                      Genre Image* (PNG/JPEG/JPG) (500x500)
                    </label>
                    <input
                      type="file"
                      id="myFile"
                      accept=".png, .jpg, .jpeg"
                      name="myFile"
                      onChange={handleGenreImageUpload}
                    />
                    <button className="createButton">Add</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        {/*Add category modal*/}
        {openCategoryModal ? (
          <div className="modal">
            <div className="modalInner">
              <p
                className="closeModal"
                onClick={() => {
                  setCategoryModal(false);
                  setErrorMessage(false);
                }}>
                X
              </p>
              <div style={{ margin: 40 }}>
                {errorMessage ? (
                  <div style={{ color: "red", fontSize: 10 }}>
                    Category already exist
                  </div>
                ) : (
                  ""
                )}

                <form
                  className="form-new"
                  onSubmit={handleCategorySubmit}
                  method="post"
                  encType="multipart/form-data"
                  action="/upload">
                  <div
                    className="formInput"
                    style={{ display: "flex", flexDirection: "column" }}>
                    <label className="label-form">Category Name*</label>
                    <input
                      type="text"
                      placeholder="Health Care"
                      className="input-form"
                      value={categoryName}
                      onChange={(e) => {
                        setCategoryName(e.target.value);
                      }}
                      required
                    />
                    <label className="label-form">Category Description</label>
                    <textarea
                      className="input-form"
                      value={categoryDescription}
                      onChange={(e) => {
                        setcategoryDescription(e.target.value);
                      }}
                    />

                    <label className="label-form">
                      Category Image* (PNG/JPEG/JPG) (320x320)
                    </label>
                    <input
                      type="file"
                      id="myFile"
                      accept=".png, .jpg, .jpeg"
                      name="myFile"
                      onChange={handleCategorymageUpload}
                    />
                    <button className="createButton">Add</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        {/*Add measurment modal*/}
        {openMeasurementModal ? (
          <div className="modal">
            <div className="modalInner">
              <p
                className="closeModal"
                onClick={() => {
                  setMeasurementModal(false);
                  setErrorMessage(false);
                }}>
                X
              </p>
              <div style={{ margin: 40 }}>
                {errorMessage ? (
                  <div style={{ color: "red", fontSize: 10 }}>
                    Measurement Unit already exists
                  </div>
                ) : (
                  ""
                )}

                <form className="form-new" onSubmit={handleAddMeasurement}>
                  <div
                    className="formInput"
                    style={{ display: "flex", flexDirection: "column" }}>
                    <label className="label-form">Unit Name*</label>
                    <input
                      type="text"
                      placeholder="Health Care"
                      className="input-form"
                      value={measurementName}
                      onChange={(e) => {
                        setMeasurementName(e.target.value);
                      }}
                      required
                    />
                    <button className="createButton">Add</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        <div className="top-new">
          <h1 className="heading-top">{title}</h1>
        </div>
        <div className="bottom">
          <div className="right">
            <form
              className="form-new"
              onSubmit={handleSubmit}
              method="post"
              encType="multipart/form-data"
              action="/upload">
              <div className="formInput">
                {/* Name */}
                <label className="label-form">Medicine Name*</label>
                <input
                  type="text"
                  placeholder="Health Care"
                  className="input-form"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
                <label className="label-form">Retail Price*</label>
                <input
                  type="number"
                  placeholder="45"
                  className="input-form"
                  value={price}
                  onChange={(e) => {
                    setPrice(e.target.value);
                  }}
                />
                {/* Categories */}
                <div
                  className="label-form"
                  style={{
                    justifyContent: "space-between",
                    margin: "5px 0px",
                  }}>
                  <label className="label-form">Medicine Category*</label>
                  <IoIosAddCircle
                    size={25}
                    style={{ cursor: "pointer" }}
                    onClick={() => setCategoryModal(true)}
                  />
                </div>
                <select
                  value={category}
                  onChange={(e) => {
                    setcategory(e.target.value);
                  }}
                  className="input-form">
                  <option value=""></option>
                  {allCategories.map((row) => (
                    <option value={row._id} key={row.name}>
                      {row.name}
                    </option>
                  ))}
                </select>
                {/* Genre */}
                <div
                  className="label-form"
                  style={{
                    justifyContent: "space-between",
                    margin: "5px 0px",
                  }}>
                  <label className="label-form">Medicine Genre*</label>
                  <IoIosAddCircle
                    size={25}
                    style={{ cursor: "pointer" }}
                    onClick={() => setGenreModal(true)}
                  />
                </div>
                <select
                  value={genre}
                  onChange={(e) => {
                    setGenre(e.target.value);
                  }}
                  className="input-form">
                  <option value=""></option>
                  {allGenres.map((row) => (
                    <option value={row._id} key={row.name}>
                      {row.name}
                    </option>
                  ))}
                </select>
                {/*Symptoms*/}
                <div
                  className="label-form"
                  style={{
                    justifyContent: "space-between",
                    margin: "5px 0px",
                  }}>
                  <label className="label-form">Medicine Symptoms*</label>
                  <IoIosAddCircle
                    size={25}
                    style={{ cursor: "pointer" }}
                    onClick={() => setSymptomModal(true)}
                  />
                </div>
                <Select
                  options={updatedSymptoms}
                  isMulti
                  value={symptoms}
                  onChange={(selected) => setsymptoms(selected)}
                />
                {/* Description */}
                <label htmlFor="description" className="label-form">
                  Medicine Description*:
                </label>

                <ReactQuill
                  value={description}
                  onChange={(value) => setDescription(value)}
                  modules={quillModules}
                  formats={quillFormats}
                  placeholder="Write something..."
                />

                {/* Ingredients*/}
                <div
                  className="label-form"
                  style={{
                    justifyContent: "space-between",
                    margin: "5px 0px",
                  }}>
                  <label className="label-form">Medicine Ingredients*</label>
                  <IoIosAddCircle
                    size={25}
                    style={{ cursor: "pointer" }}
                    onClick={() => setMeasurementModal(true)}
                  />
                </div>

                <div
                  style={{ display: "flex", justifyContent: "space-between" }}>
                  <input
                    type="text"
                    placeholder="Ingredient Name"
                    className="input-form ingrdientNameInput"
                    value={ingredientName}
                    onChange={(e) => {
                      setIngredientName(e.target.value);
                    }}
                  />
                  <input
                    type="number"
                    placeholder="5 mg"
                    className="input-form weightageInput"
                    style={{ width: 120 }}
                    value={weightage}
                    onChange={(e) => {
                      setWeightage(e.target.value);
                    }}
                  />
                  <select
                    value={measurement}
                    onChange={(e) => setMeasurement(e.target.value)}>
                    <option value=""></option>
                    {allMeasurements.map((row) => (
                      <option value={row.name} key={row._id}>
                        {row.name}
                      </option>
                    ))}
                  </select>

                  <div
                    onClick={addIngredients}
                    style={{
                      backgroundColor: "black",
                      color: "white",
                      cursor: "pointer",
                      padding: 5,
                    }}>
                    Add
                  </div>
                </div>
                <ul>
                  {ingredients.map((item, index) => (
                    <li
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}>
                      <p>{item.ingredientName}</p>

                      <p>
                        {item.weightage} <span> {item.measurement}</span>
                      </p>
                    </li>
                  ))}
                </ul>
                {/* Instructions*/}
                {/*
                <label className="label-form">Medicine Instructions</label>
                <ReactQuill
                  value={instructions}
                  onChange={(value) => setInstructions(value)}
                    />
                    */}
                {/* Benefits*/}
                <label className="label-form">Medicine Benefits</label>
                <ReactQuill
                  value={benefits}
                  onChange={(value) => setBenefits(value)}
                  modules={quillModules}
                  formats={quillFormats}
                  placeholder="Write something..."
                />
                {/* SideEffects*/}
                <label className="label-form">Medicine Side-effects</label>
                <ReactQuill
                  value={sideeffects}
                  onChange={(value) => setSideEffects(value)}
                  modules={quillModules}
                  formats={quillFormats}
                  placeholder="Write something..."
                />
                {/* Directions*/}
                <label className="label-form">Medicine Directions</label>
                <ReactQuill
                  value={directions}
                  onChange={(value) => setDirections(value)}
                  modules={quillModules}
                  formats={quillFormats}
                  placeholder="Write something..."
                />

                {/*  Images */}
                <label className="label-form">
                  Medicine Image* (PNG/JPEG/JPG) (550x420) (Max 5)
                </label>
                <input
                  type="file"
                  accept=".png, .jpg, .jpeg"
                  onChange={handleImageUpload}
                  multiple
                />
                {/* Banner Image */}
                <label className="label-form">
                  Medicine Banner Image (PNG/JPEG/JPG) (375x135) (Max 1)
                </label>
                <input
                  type="file"
                  accept=".png, .jpg, .jpeg"
                  onChange={(e) => setBannerImage(e.target.files[0])}
                />

                {/*Sumit Buttom*/}
                <button className="createButton">Add</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewMedicine;
