
  export const categoryInputs = [
    {
      id: 1,
      label: "Name",
      type: "text",
      placeholder: "Areeba",
    },
    {
      id: 2,
      label: "Email",
      type: "text",
      placeholder: "Email",
    },
    {
      id: 3,
      label: "No of Devices",
      type: "text",
      placeholder: "22",
    },
   
    {
      id: 4,
      label: "DeviceId",
      type: "text",
      placeholder: "23245",
    },
  ];
  export const categoryUpdate = [
    {
      id: 1,
      label: "Title",
      type: "text",
      placeholder: "Apple Macbook Pro",
    },
    {
      id: 2,
      label: "Description",
      type: "text",
      placeholder: "Description",
    },
    {
      id: 3,
      label: "Category",
      type: "text",
      placeholder: "Computers",
    },
    {
      id: 4,
      label: "Price",
      type: "text",
      placeholder: "100",
    },
    {
      id: 5,
      label: "Stock",
      type: "text",
      placeholder: "in stock",
    },
  ];
  