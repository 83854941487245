import "../../style/datatable.css";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import PopupAlert from "../../components/popupalert/popupAlert";

const DatatableBlock = () => {
  const [blocks, setmedicines] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [popUpShow, setPopupshow] = useState(false);
  const [popUpText, setPopupText] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [images, setImages] = useState([]);
  const [openMedicinesModal, setMedicineModal] = useState(false);
  const [updatedMedicine, setUpdatedMedicines] = useState([]);
  const [allMedicines, setAllMedicines] = useState([]);
  const [name, setName] = useState("");
  const [medicineData, setMedicineData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get("https://paul-brooks-api-7a80d7089006.herokuapp.com/blocks")
      .then((response) => {
        setmedicines(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get("https://paul-brooks-api-7a80d7089006.herokuapp.com/medicines")
      .then((response) => {
        setAllMedicines(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [blocks, allMedicines]);

  const handleDelete = (id) => {
    axios
      .delete("https://paul-brooks-api-7a80d7089006.herokuapp.com/blocks/" + id)
      .then((response) => {
        console.log(response.data);
        setmedicines(blocks.filter((el) => el._id !== id));
      });

    setPopupshow(true);
    setPopupText("Medicine Deleted");
    setTimeout(() => {
      setPopupshow(false);
    }, 2000);
  };

  const handleDeleteSelectedRows = () => {
    selectedRows.forEach((row) => {
      axios
        .delete("https://paul-brooks-api-7a80d7089006.herokuapp.com/blocks/" + row)
        .then((response) => {
          window.location.reload();
          setPopupshow(true);
          setPopupText(`${selectedRows.length} Medicines Deleted`);
        });
    });
    setTimeout(() => {
      setPopupshow(false);
    }, 2000);
    setSelectedRows([]);
  };

  const handleUpdateName = (e) => {
    e.preventDefault();
    const data = { name: name, medicineData: updatedMedicine };
    axios
      .patch(
        `https://paul-brooks-api-7a80d7089006.herokuapp.com/blocks/${selectedRow._id}`,
        data
      )
      .then((response) => {
        setUpdatedMedicines(response.data);
        setPopupText("Block Updated");
        setPopupshow(true);
        setTimeout(() => {
          setPopupshow(false);
          window.location.reload();
        }, 2000);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleAdd = (e) => {
    e.preventDefault();

    const formData = new FormData();

    images.forEach((image) => {
      formData.append("images", image);
    });
    if (images.length + updatedMedicine.length < 6) {
      axios
        .patch(
          `https://paul-brooks-api-7a80d7089006.herokuapp.com/blocks/addImage/${selectedRow._id}`,
          formData
        )
        .then((response) => {
          setUpdatedMedicines(response.data);
          setPopupText("New images added");
          setPopupshow(true);
          setTimeout(() => {
            setPopupshow(false);
            window.location.reload();
          }, 2000);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      window.alert("No maximum 5 imaegs allowed");
    }
  };

  const handleDeleteData = (index) => {
    axios
      .delete(
        `https://paul-brooks-api-7a80d7089006.herokuapp.com/blocks/deleteMedicineData/${selectedRow._id}/${index}`
      )
      .then((response) => {
        setMedicineData(response.data);
        setPopupText("Medicine in block Delete added");
        setPopupshow(true);
        setTimeout(() => {
          setPopupshow(false);
        }, 2000);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // Function to handle checkbox changes
  const handleCheckboxChange = (medicineId) => {
    // Check if the medicineId exists in updatedMedicine array
    const index = updatedMedicine.findIndex(
      (medicine) => medicine.medicineId === medicineId
    );

    if (index !== -1) {
      // If medicineId exists, remove it from updatedMedicine
      const updatedMedicines = [...updatedMedicine];
      updatedMedicines.splice(index, 1);
      setUpdatedMedicines(updatedMedicines);
    } else {
      // If medicineId doesn't exist, add it to updatedMedicine
      const selectedMedicine = allMedicines.find(
        (medicine) => medicine.medicineId === medicineId
      );
      if (selectedMedicine) {
        const updatedMedicines = [...updatedMedicine, selectedMedicine];
        setUpdatedMedicines(updatedMedicines);
      }
    }
  };

  const actionColumn = [
    { field: "name", headerName: "Block Name", width: 150 },
    {
      field: "MedicineData",
      headerName: "No. of Medicines",
      width: 150,
      renderCell: (params) => {
        return (
          <p style={{ color: "black" }}>{params.row.medicineData.length}</p>
        );
      },
    },

    {
      field: "action",
      headerName: "Action",
      width: 400,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <div
              className="deleteButton"
              onClick={() => {
                setSelectedRow(params.row);
                setMedicineModal(true);
                setMedicineData(params.row.medicineData);
              }}>
              View
            </div>
            <div
              className="deleteButton"
              onClick={() => {
                setSelectedRow(params.row);
                setName(params.row.name);
                setUpdatedMedicines(params.row.medicineData);

                setOpenModal(true);
              }}>
              Update
            </div>
            <div
              className="deleteButton"
              onClick={() => handleDelete(params.row._id)}>
              Delete
            </div>
          </div>
        );
      },
    },
  ];
  return (
    <div className="datatable">
      <div className="datatableTitle">
        Block Sessions
        <Link to="/blocks/new" className="link-new">
          Add Block Session
        </Link>
      </div>
      {selectedRows.length > 0 ? (
        <button
          onClick={() => {
            handleDeleteSelectedRows();
          }}>
          Delete Selected Rows
        </button>
      ) : null}
      {openModal ? (
        <div className="modal">
          <div className="modalInner" style={{ width: "30%" }}>
            <p className="closeModal" onClick={() => setOpenModal(false)}>
              &times;
            </p>
            <div style={{ margin: 40 }}>
              <form style={{ display: "flex", flexDirection: "column" }}>
                <h5>Update</h5>
                <input
                  type="text"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
                <h5>Selected Medicines</h5>
                {allMedicines.map((row, index) => {
                  // Check if the medicineId exists in updatedMedicine array
                  const isChecked = updatedMedicine.some(
                    (medicine) => medicine.medicineId === row.medicineId
                  );

                  return (
                    <div
                      key={index}
                      style={{
                        width: "100%",
                        margin: 10,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}>
                      <input
                        type="checkbox"
                        checked={isChecked}
                        onChange={() => handleCheckboxChange(row.medicineId)}
                      />
                      <img
                        src={`https://paul-brooks-api-7a80d7089006.herokuapp.com/images/${row.images[0]}`}
                        alt={row.images[0]}
                        width={50}
                        height={50}
                        style={{ marginLeft: 20 }}
                      />
                      <p style={{ color: "black", marginLeft: 20 }}>
                        {row.name}
                      </p>
                    </div>
                  );
                })}
                <button onClick={handleUpdateName}>Update</button>
              </form>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {openMedicinesModal ? (
        <div className="modal">
          <div className="modalInnerMedicine">
            <p className="closeModal" onClick={() => setMedicineModal(false)}>
              &times;
            </p>
            <div style={{ margin: 40 }}>
              <h3 style={{ textAlign: "center" }}>{selectedRow.name}</h3>
              <div>
                {medicineData.map((row, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        margin: 20,

                        alignItems: "center",
                      }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}>
                        <img
                          src={`https://paul-brooks-api-7a80d7089006.herokuapp.com/images/${row.images[0]}`}
                          alt={row.images[0]}
                          width={"100"}
                          height={"100"}
                        />
                        <h6
                          style={{
                            marginLeft: 30,
                          }}>
                          {row.name}
                        </h6>
                      </div>
                      <p
                        className="deleteMedImages"
                        onClick={(e) => {
                          e.preventDefault();
                          handleDeleteData(index);
                        }}>
                        &times;
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {popUpShow ? (
        <PopupAlert popUpText={popUpText} backgroundColor={"red"} />
      ) : (
        ""
      )}
      {loading ? (
        <CircularProgress />
      ) : (
        <DataGrid
          className="datagrid"
          rows={blocks}
          columns={actionColumn}
          checkboxSelection={true}
          onSelectionModelChange={(newSelection) => {
            setSelectedRows(newSelection);
          }}
          getRowId={(row) => {
            return row._id;
          }}
          pageSize={9}
          rowsPerPageOptions={[9]}
        />
      )}
    </div>
  );
};

export default DatatableBlock;
